<template>
  <div>
    <!-- todo - https://stackoverflow.com/questions/50392918/vuetify-how-to-preselect-active-tab
     -->
    <v-tabs v-model="activetab" align-with-title>
      <v-tab  v-for="tab of tabs"  :key="tab.id" @click="WarnUser(tab.to)">
        <span>{{ tab.caption }}</span>
      </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'GeneralLedger',
  components: {
  },
  data: () => ({
    dialog: false,
    path: '',
    activetab: 0,
    tabs: [
      { id: 1, to: { path: '/gl/transactions' }, caption: 'General Ledger' },
      { id: 2, to: { path: '/gl/browse/headings' }, caption: 'Browse' },
    ],
  }),
  methods: {
    WarnUser(path) {
      this.path = path;
      const currentpath = this.$router.currentRoute.path;
      if (path.path !== currentpath) {
        this.$router.push(path);
      }
    },
  },
  computed: {
  },
};
</script>
